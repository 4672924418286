import {
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import moment from 'moment';
import React, {useContext, useEffect, useMemo, useState} from "react";
import ShowIf from "components/common/ShowIf";
import './style.scss';
import {convertProxyUrl} from "components/common/ImageGallery";
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import _ from 'lodash';
import {getGameScoreData} from "pages/Trends/TrendGames";
import {SortingForm} from "pages/Trends";
import PerformanceUtils from "helpers/PerformanceUtils";
import GameGrid from "components/common/GameGrid";

const getDeveloper = 'getDeveloper';

const DeveloperDetailsPanel = ({containerRef}) => {

  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;
  const [developer, setDeveloper] = useState();

  const {developer_name, developer_store} = detailsPanel;

  useEffect(() => {
    call(getDeveloper, {name: developer_name, store: developer_store}).then(response => {
      if (response.ok) {
        setDeveloper(response.body);
      }
    });
  }, [developer_name, developer_store]);

  /*useEffect(() => {
    function onResize() {
      window.dispatchEvent(new CustomEvent('resize'));
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'));
      }, 400)
    }

    onResize();
    return onResize;
  }, []);*/

  return (
    <>
      <ShowIf condition={!developer}>
        <div className="m-4 text-align-center">
          <CircularProgress size={55}/>
        </div>
      </ShowIf>
      <ShowIf condition={!!developer}>
        <DeveloperContent
          key={(developer || {}).id}
          developer={developer}
        />
      </ShowIf>
    </>
  )
};

const DEFAULT_ARRAY = [];

const getGamesInformation = 'getGamesInformation';
const getGamesTrends = 'getGamesTrends';

const SORT_OPTIONS = {
  release: 'Release Date',
  rank: 'Chart Rank',
}

const DeveloperContent = ({developer}) => {

  const {game_list = DEFAULT_ARRAY} = developer;
  const [games, setGames] = useState(undefined);
  const [trends, setTrends] = useState({});
  const [image, setImage] = useState(undefined);
  const [sorting, setSorting] = useState(SORT_OPTIONS);
  const {call} = useContext(APIContext);

  useEffect(() => {

    call(getGamesTrends, {data: {ids: game_list, location: 'United States'}}).then(response => {
      if (response.ok) {
        let result = {};
        response.body.forEach(trend => {
          result[trend.game_id] = trend.infos;
        })
        setTrends(result);
      }
    });

    call(getGamesInformation, {data: {ids: game_list}}).then(response => {
      if (response.ok) {
        setGames(response.body)
        if (response.body[0]) {
          let game = (response.body || []).find(game => (game.screenshots || []).length > 0);
          if (game) {
            setImage(game.screenshots[0]);
          }
        }
      }
    });
  }, [game_list]);

  const sortedGames = useMemo(() => {
    let sortedGames = [...(games || [])];
    if (sorting === SORT_OPTIONS.rank) {
      sortedGames = sortedGames.map(g => {
        let trend = (trends[g._id] || [])[0] || {};
        return {...g, current_rank: trend.current_rank, date_inverse: moment().unix() - g.release_date};
      });
      sortedGames = _.sortBy(sortedGames, ['current_rank', 'date_inverse']).map(g => {
        delete g.current_rank;
        delete g.date_inverse;
        return g;
      });
    } else if (sorting === SORT_OPTIONS.release) {
      sortedGames = _.reverse(_.sortBy(games, ['release_date']));
    }
    return sortedGames;
  }, [games, sorting, trends])

  function onChangeSort(values) {
    const {sorting} = values;
    setSorting(sorting);
  }

  const forceDate = sorting === SORT_OPTIONS.release;
  const useChartSummary = sorting === SORT_OPTIONS.rank;

  const altText = useMemo(() => sortedGames.map(game => getGameScoreData({
    game_info: trends[game._id],
    game,
  },
    false,
    forceDate,
    true)
  ), [sortedGames, forceDate, useChartSummary]);

  return (
    <div className="developer-details content p0">
      <Header
        developer={developer}
        image={image}
      />
      <div className="body">
        <Statistics developer={developer} games={games}/>
        <span className="mt-5 d-block mx-auto w-100 text-align-center font-weight-bold font-size-md">
          From the same developer
        </span>
        <div className="info my-4 px-1">
          <ShowIf condition={!games}>
            <div className="text-align-center m-4">
              <CircularProgress size={55}/>
            </div>
          </ShowIf>
          <ShowIf condition={!!games}>
            <ShowIf condition={(games || []).length === 0}>
              <span className="d-block text-align-center m-auto">No games found!</span>
            </ShowIf>
            <ShowIf condition={(games || []).length > 0}>

              <SortingForm
                options={Object.keys(SORT_OPTIONS).map(key => {
                  return {value: SORT_OPTIONS[key], label: SORT_OPTIONS[key]}
                })}
                onChange={onChangeSort}
                formId={"Developer.Sorting." + developer._id}
              />

              <GameGrid
                spacing={3}
                games={sortedGames}
                altText={altText}
                includeStats={false}
                gameProps={{
                  lazyLoad: false
                }}
              />

            </ShowIf>
          </ShowIf>
        </div>
      </div>
    </div>
  )
};

const DEFAULT_OBJECT = {};

const Statistics = ({developer = DEFAULT_OBJECT, games = DEFAULT_ARRAY}) => {

  const {game_list, last_release_date, games_now_in_charts, current_rank, current_genre, current_chart} = developer;

  const firstDate = useMemo(() => {
    let filtered = games.filter(g => !!g.release_date);
    let sorted = PerformanceUtils.sortBy(filtered, ['release_date'], false);
    let chosen = sorted[0];
    if (chosen) {
      return moment.unix(chosen.release_date).format('MMMM YYYY');
    }
  }, [games]);

  return (<div className="statistics text-align-center">
    <Grid container spacing={1}>
      <Grid item md={6} className="info-element">
        <span className="info-title small">Games in Charts</span>
        <span className="info-content">{(games_now_in_charts || []).length}</span>
      </Grid>
      <Grid item md={6} className="info-element">
        <span className="info-title small">Last Release</span>
        <span className="info-content">{moment.unix(last_release_date).fromNow()}</span>
      </Grid>
      <Grid item md={6} className="info-element">
        <span className="info-title small">Released Games</span>
        <div className="info-content d-flex flex-column">
          <span>{(game_list || []).length}</span>
          <ShowIf condition={!!firstDate}>
            <span className="info-small"> Since {firstDate}</span>
          </ShowIf>
        </div>
      </Grid>
      <Grid item md={6} className="info-element">
        <span className="info-title small">Current Highest Rank</span>
        <span className="info-content">
          <div className="info-content d-flex flex-column">
            <span>{current_rank || "None"}</span>
            <ShowIf condition={!!current_genre}>
              <span className="info-small">{current_genre}, {current_chart}</span>
            </ShowIf>
          </div>
        </span>
      </Grid>
    </Grid>
  </div>)
}

const Header = ({developer, image = DEFAULT_OBJECT}) => {
  const url = useMemo(() => convertProxyUrl(image), [image]);
  let style = {background: `linear-gradient(rgba(63, 102, 170, 0.75), rgba(63, 102, 170, 0.75)), url(${url})`};
  return (
    <div className="header cover-header" style={style}>
      <div className="image-wrapper">
        <span className="developer-title text-align-center w-100 d-block">
          <span className="developer-header text-align-center w-100 d-block">
          Developer
        </span>
          {developer.developer}
      </span>
      </div>
    </div>
  )
};
export default DeveloperDetailsPanel;
