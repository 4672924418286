import React, {useContext, useState, useEffect} from 'react';
import DetailsPanelContext from "context/DetailsPanelContext";
import SocketContext from "context/SocketContext";
import CacheContext from "context/CacheContext";

function useGDDState() {

  const [menu, setMenu] = useState(false);
  const [preview, setPreview] = useState(false);
  const [exportProject, setExportProject] = useState(false);
  const [suggestions, setSuggestions] = useState([])

  const {cache} = useContext(CacheContext);
  const {track} = useContext(SocketContext);
  const {hidePanel} = useContext(DetailsPanelContext);

  const {detailsPanel} = cache;

  useEffect(() => {
    if (preview) setMenu(false);
  }, [preview]);

  function reset() {
    setMenu(false);
    setPreview(false)
    setExportProject(false);
  }

  function closeMenu() {
    track('gdd.menu.close');
    setMenu(false);
  }

  function openMenu(data) {
    if (!preview || data?.component?.section === "concepts") {
      setMenu(data);
      if (detailsPanel.mode) hidePanel();
    }
  }

  return {menu, openMenu, closeMenu, preview, setPreview, reset, suggestions, setSuggestions, setExportProject, exportProject}
}

export default useGDDState;
