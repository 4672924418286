import {
  Grid,
  CircularProgress
} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import ShowIf from "components/common/ShowIf";
import './style.scss';
import CacheContext from "context/CacheContext";
import APIContext from "context/APIContext";
import GameCard from "components/common/GameCard";
import {getGameScoreData} from "pages/Trends/TrendGames";

const DEFAULT_OBJECT = {};
const DEFAULT_ARRAY = [];
const getGamesInformation = 'getGamesInformation';
const getGamesTrends = 'getGamesTrends';

const CompetitiveAnalysisDetailsPanel = () => {

  const {call} = useContext(APIContext);
  const {cache} = useContext(CacheContext);
  const {detailsPanel} = cache;

  const [games, setGames] = useState([]);
  const [gameInfos, setGameInfos] = useState([]);

  const {cluster = DEFAULT_OBJECT, metric = DEFAULT_OBJECT, game_ids} = detailsPanel;

  const gameIds = game_ids || metric.game_ids || cluster.game_ids;

  useEffect(() => {
    call(getGamesInformation, {data: {ids: gameIds}}).then(response => {
      if (response.ok) {
        setGames(response.body);
      }
    });
    call(getGamesTrends, {data: {ids: gameIds}}).then(response => {
      if (response.ok) {
        let newInfos = {};
        response.body.forEach(trend => {
          if (trend.game_id) newInfos[trend.game_id] = trend.infos;
        });
        setGameInfos(newInfos);
      }
    });
  }, [gameIds]);

  return (
    <div className="competitive-analysis-details-panel">
      <ShowIf condition={games.length > 0}>
        <Games games={games} gameInfos={gameInfos}/>
      </ShowIf>
      <ShowIf condition={games.length === 0}>
        <div className="text-align-center m-4 loading">
          <CircularProgress size={55}/>
        </div>
      </ShowIf>
    </div>
  )
};

const Games = ({games = DEFAULT_ARRAY, gameInfos = DEFAULT_ARRAY}) => {

  const {cache} = useContext(CacheContext);
  const {trialExpired} = cache;

  return (
    <div className="games">
      <ShowIf condition={games.length > 0}>
        <Grid container justifyContent="flex-start" spacing={3}>
          {games.map((game, index) => {
              let gameInfo = gameInfos[game._id] || {};
              let altText = getGameScoreData({game_info: gameInfo, game});
              return (
                <Grid
                  item
                  key={game._id}
                  xs={6}
                >
                  <GameCard
                    game={game}
                    altText={altText}
                    lazyLoad={false}
                    locked={trialExpired && index >= 3}
                  />
                </Grid>);
            }
          )}
        </Grid>
      </ShowIf>
      <ShowIf condition={games.length === 0}>
        <div className="text-align-center m-4">
          <CircularProgress size={55}/>
        </div>
      </ShowIf>
    </div>
  )
}

export default CompetitiveAnalysisDetailsPanel;
