import React, {useEffect} from 'react';
import localforage from 'localforage';

const setVal = {};

function usePersistedState(key, defaultValue, useLocalStorage = false) {
  const storage = useLocalStorage ? localStorage : localforage;

  const defaultValueSet = useLocalStorage ? getItemLocalStorage(key, defaultValue) : defaultValue

  const [state, setState] = React.useState(defaultValueSet);
  const [loading, setLoading] = React.useState(!useLocalStorage);

  useEffect(() => {
    let persistValue = state;

    if (typeof persistValue === "object" || Array.isArray(persistValue))
      persistValue = JSON.stringify(persistValue);

    if (useLocalStorage) {
      try {
        storage.setItem(key, persistValue);
      } catch (err) {
        clearSession(err);
      }
    } else {
      storage.setItem(key, persistValue).catch(err => {
        clearSession(err);
      });
    }
  }, [state, useLocalStorage]);

  function clearSession(err) {
    console.log(key, err)
  }

  useEffect(() => {
    setVal[key] = false;
    if (!useLocalStorage) {
      storage.getItem(key).then(value => {
        let toSave = value;

        if (value === undefined || value === null)
          toSave = defaultValue;
        else if (value === "true")
          toSave = true;
        else if (value === "false")
          toSave = false;
        else if (shouldSerialize(value))
          toSave = JSON.parse(value) || defaultValue;

        if (!setVal[key])
          setState(toSave);
        setLoading(false);
      }).catch(err => {
        clearSession(err);
      });
    }
  }, [key, useLocalStorage])

  function deleteState() {
    if (useLocalStorage) {
      try {
        storage.removeItem(key);
        setState(defaultValue);
      } catch (err) {
        clearSession(err);
      }
    } else {
      storage.removeItem(key)
      setState(defaultValue);
    }
  }

  function setStateWrapper(val) {
    setVal[key] = true;
    setState(val);
  }

  return [state, setStateWrapper, loading, deleteState];
}

function getItemLocalStorage(key, defaultValue) {
  let value = localStorage.getItem(key);

  if (value === undefined || value === null) return defaultValue;

  if (value === "true")
    return true;

  if (value === "false")
    return false;

  if (shouldSerialize(value))
    return JSON.parse(value);

  return value;
}

function shouldSerialize(value) {
  return (typeof value === "string") && value !== "undefined" && (value.startsWith("{") || value.startsWith("["));
}

export default usePersistedState;
