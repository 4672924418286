import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import APIContext from "context/APIContext";
import PageTitle from "components/layout-components/PageTitle";
import './style.scss';
import FormikPersist from "components/utils/FormikPersist";
import {Chip, CircularProgress, Grid, Tooltip} from "@material-ui/core";
import {FormikSelectField} from "formik-material-fields";
import {GeneratingButton} from "components/Controls/MyButton";
import ShowIf, {ShowVisibleIf} from "components/common/ShowIf";
import {Formik, Form} from "formik";
import UniversalInput, {
  ChangeDataOnLocation,
  convertUniversalInput,
  topicToUniversalOption
} from "components/Controls/UniversalInput";
import {GameIcon} from "components/common/GameCard";
import * as Yup from "yup";
import usePersistedState from "hooks/usePersistedState";
import DetailsPanelContext from "context/DetailsPanelContext";
import Plot from "react-plotly.js";
import {SOURCE_LABELS} from "pages/Trends";
import PerformanceUtils from "helpers/PerformanceUtils";
import {Hint} from "scenes/Headquarters";
import ErrorBoundary from "components/utils/ErrorBoundary";
import _ from 'lodash';
import SocketContext from "context/SocketContext";
import {useLocation} from "react-router";
import LoadingTip, {LOADING_TIPS_SECTIONS} from "components/utils/LoadingTip";
import AuthContext from "../../context/AuthContext";

const competitiveAnalysis = 'competitiveAnalysis';
const getTrendOptions = 'getTrendOptions';
const getGameInformation = 'getGameInformation';
const getGamesInformation = 'getGamesInformation';
const getTopChartsCountries = 'getTopChartsCountries';

const DEFAULT_ARRAY = [];

const SOURCES_WITHOUT_COUNTRY = ['steam', 'itch'];

const SHOWN_GAMES = 5;

const DEFAULT_REQUEST = {
  country: 'United States',
  sources: 'appstore+playstore',
  topics: []
}

var lastReqId;

const CompetitiveAnalysis = ({}) => {

  const location = useLocation();
  const {auth} = useContext(AuthContext);
  const {track} = useContext(SocketContext);
  const {showCompetitiveAnalysis} = useContext(DetailsPanelContext);
  const {call, loading} = useContext(APIContext);

  const defaultSources = auth.user.platform === "Mobile" ? 'appstore+playstore' : (auth.user.platform === 'Desktop' ? 'steam' : DEFAULT_REQUEST.sources);

  const [results, setResults, loadingResults] = usePersistedState('CompetitiveAnalysis.results4', undefined);
  const [selectedCluster, setSelectedCluster] = usePersistedState('CompetitiveAnalysis.selectedCluster1', undefined);
  const [resizeIndex, setResizeIndex] = useState(0);
  const [sourceCountry, setSourceCountry] = usePersistedState('CompetitiveAnalysis.sourceCountry', {
    country: DEFAULT_REQUEST.country,
    sources: defaultSources
  });

  useEffect(() => {
    function handleResize() {
      setResizeIndex(prevState => prevState + 1);
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    if (!loadingResults && !results?.clusters && !results?.metrics) {
      onSubmit(undefined, undefined, []);
    }
  }, [results, loadingResults])

  async function onSubmit(values, formik, currentClusters = results?.clusters, sourceCountryData = sourceCountry, formikUpdate = true, overrideLocationData = false) {
    if (!!location?.state?.data && !overrideLocationData) return;

    const topics = (currentClusters || [])
      .filter(cluster => !cluster.is_benchmark)
      .map(cluster => cluster.topic_query);

    let newSelectedCluster = topics[0]?.id;

    if (sourceCountryData) delete sourceCountryData.key;

    let data;

    if (formik) {
      data = {...(values || {}), ...(sourceCountryData || {})};

      if (SOURCES_WITHOUT_COUNTRY.includes(data.sources)) {
        data.country = "All";
      }

      let id = PerformanceUtils.generateId();
      newSelectedCluster = id;

      let newTopics = [
        ...topics,
        {
          id,
          cluster_width: data.cluster_width,
          ...convertUniversalInput(data.search),
          genres_filter: [],
        }
      ];

      data = {
        country: data.country,
        sources: data.sources,
        topics: newTopics,
      };
    } else {
      data = {
        ...DEFAULT_REQUEST,
        ...(values || {}),
        ...sourceCountryData,
        topics,
      }
    }

    let lastId = PerformanceUtils.generateId();
    lastReqId = lastId;
    let response = await call(competitiveAnalysis, {data});
    if (response.ok && lastReqId === lastId) {
      if (formik && formikUpdate) {
        formik.setValues({...values, search: []});
      }
      setResults(response.body);

      if (!newSelectedCluster)
        newSelectedCluster = response.body.clusters[0]?.topic_query?.id;

      setSourceCountry({...sourceCountryData, key: lastId});

      setSelectedCluster(newSelectedCluster);
    }
  }

  function onRemoveCluster(cluster) {
    let newResults = {
      ...results,
      clusters: results.clusters.filter(({topic_query}) => topic_query?.id !== cluster.topic_query?.id),
      metrics: []
    };
    setResults(newResults);
    setSelectedCluster(cluster.topic_query?.id === selectedCluster ? newResults.clusters[0]?.topic_query?.id : selectedCluster);
    onSubmit(undefined, undefined, newResults.clusters, sourceCountry);
    track('competitive-analysis.remove-cluster', {cluster});
  }

  function onChangedSourceCountry(name, value) {
    track('competitive-analysis.changed-source-country', {[name]: value});
    setSourceCountry(prevState => {
      return {
        ...prevState,
        [name]: value
      }
    });
    onSubmit(undefined, undefined, results.clusters, {...sourceCountry, [name]: value});
  }

  const resultClusters = results?.clusters || DEFAULT_ARRAY;

  const orderedClusters = useMemo(() => {
    let nonBenchmarks = _.orderBy(resultClusters.filter(cluster => !cluster.is_benchmark), 'topic_query.added_time');
    let benchmarks = resultClusters.filter(cluster => !!cluster.is_benchmark);
    return [..._.reverse(nonBenchmarks), ...benchmarks];
  }, [resultClusters]);

  function onSeeAll(data) {
    let id = data.cluster?.topic_query?.id || data.metric?.id;
    showCompetitiveAnalysis({...data, id});
    track('competitive-analysis.see-all-games', {data});
  }

  return (
    <div className="competitive-analysis w-100">
      <PageTitle
        titleHeading="Market Analysis"
        titleDescription="Analyse and compare the market conditions of game topics"
      />
      {!loadingResults && <AnalysisForm
        loading={loading[competitiveAnalysis]}
        onSubmit={onSubmit}
        sourceCountry={sourceCountry}
      />}
      <SourceCountryForm
        onChangeForm={onChangedSourceCountry}
        loading={loading[competitiveAnalysis]}
        sourceCountry={sourceCountry}
        key={sourceCountry.key}
      />
      <ShowIf condition={loading[competitiveAnalysis]}>
        <LoadingTip
          style={{marginLeft: "60px", marginTop: "30px"}}
          section={LOADING_TIPS_SECTIONS.marketAnalysis}
          visible={loading[competitiveAnalysis]}
          key={loading[competitiveAnalysis]}
        />
        <div className="spinner">
          <CircularProgress size={150}/>
        </div>
      </ShowIf>
      <div className="clusters" key={results?.clusters?.length}>
        <Grid container spacing={4}>
          {orderedClusters.map(cluster =>
            <Grid item>
              <Cluster
                key={cluster.topic_query?.id}
                cluster={cluster}
                onRemove={onRemoveCluster}
                onSeeAll={onSeeAll}
                selectedCluster={selectedCluster}
                setSelectedCluster={setSelectedCluster}
                location={sourceCountry?.country}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <div className="metrics" key={results?.metrics}>
        {(results?.metrics || []).map((metric, index) =>
          <ErrorBoundary fallback={<span className="ml-4">An error has occurred</span>} key={metric.id}>
            <Metric
              key={metric.id}
              clusters={orderedClusters}
              metric={metric}
              selectedCluster={selectedCluster}
              setSelectedCluster={setSelectedCluster}
              onSeeAll={onSeeAll}
              resizeIndex={resizeIndex}
              location={sourceCountry?.country}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  )
};

const SourceCountryForm = ({onChangeForm, loading, sourceCountry}) => {

  const {call} = useContext(APIContext);
  const [countries, setCountries] = usePersistedState('CompetitiveAnalysis.Form.countries2', ["United States"]);
  const [sourcesOptions, setSourcesOptions] = usePersistedState('CompetitiveAnalysis.Form.sourcesOptions1', []);

  useEffect(() => {
    call(getTopChartsCountries).then(response => {
      if (response.ok) setCountries(response.body);
    });
    call(getTrendOptions, {type: "charts"}).then(response => {
      if (response.ok) {
        setSourcesOptions(response.body?.sources);
      }
    });
  }, []);

  const initialValues = {
    country: sourceCountry?.country || 'United States',
    sources: sourceCountry?.sources || (sourcesOptions || [])[0] || "appstore+playstore",
  };

  const formId = "CompetitiveAnalysis.Form.SourceCountry";

  return (
    <div className="source-country-form-wrapper my-2 pl-5">
      <Formik
        key={formId}
        initialValues={initialValues}
      >
        {(formik) => (
          <div className="d-flex flex-row">
            {/*<FormikPersist name={formId}/>*/}
            <Form>
              <Grid container>
                <Grid item xs={12} sm="auto" style={{minWidth: "200px"}}>
                  <FormikSelectField
                    disabled={loading}
                    onChange={event => {
                      onChangeForm(event.target.name, event.target.value);
                    }}
                    name="sources"
                    label="Source"
                    options={sourcesOptions.map(source => {
                      return {
                        value: source,
                        label: SOURCE_LABELS[source] || source
                      }
                    })}
                    fullWidth
                  />
                </Grid>
                <ShowVisibleIf
                  condition={!SOURCES_WITHOUT_COUNTRY.includes(formik.values.sources)}
                  className="show-visible-if">
                  <Grid item xs={12} sm="auto" style={{minWidth: "200px", marginLeft: "20px"}}>
                    <FormikSelectField
                      disabled={loading}
                      onChange={event => {
                        onChangeForm(event.target.name, event.target.value);
                      }}
                      name="country"
                      label="Country"
                      options={countries.map(country => {
                        return {value: country, label: country}
                      })}
                      fullWidth
                    />
                  </Grid>
                </ShowVisibleIf>
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}

const Cluster = ({cluster, onRemove, onSeeAll, selectedCluster, setSelectedCluster, location}) => {

  const {
    title,
    keywords,
    game_ids,
    color,
    is_benchmark,
    in_charts_now,
    highest_rank_now,
    highest_ranked_games,
    topic_query
  } = cluster;

  const {call} = useContext(APIContext);
  const {showGame} = useContext(DetailsPanelContext);
  const [topGame, setTopGame] = useState();

  const canRemove = !is_benchmark;
  const isActive = selectedCluster === topic_query?.id;
  const style = getClusterStyle(cluster, isActive)

  const topGameId = (highest_ranked_games || [])[0]

  useEffect(() => {
    if (topGameId) {
      call(getGameInformation, {id: topGameId}).then(response => {
        if (response.ok) {
          setTopGame(response.body);
        }
      });
    }
  }, [topGameId]);

  return (
    <div className="cluster rounded-box" style={style}>
      <Chip label={title} style={{background: color}}
            onClick={() => setSelectedCluster(topic_query?.id)} onDelete={canRemove ? () => onRemove(cluster) : null}/>
      <span className="keywords min-height text-capitalize">
        {keywords.join(', ')}
      </span>
      <GameIcons
        ids={game_ids}
        key={game_ids}
        onClickMore={() => onSeeAll({cluster})}
        size={cluster.size}
      />
      <div className="stats">
        <div className="highest-ranked">
          <span className="title">Highest ranked now</span>
          <div className="bottom-row">
            <ShowIf condition={highest_rank_now > 0}>
              <div className="big-number">{highest_rank_now}</div>
            </ShowIf>
            <span className="game-title text-truncate pointer"
                  onClick={() => showGame(topGame, undefined, location)}>{topGame?.title}</span>
          </div>
        </div>

        <ShowIf condition={in_charts_now > 0}>
          <div className="in-charts">
            <span className="title">In charts</span>
            <span className="blue-number pointer" onClick={() => onSeeAll({
              cluster,
              game_ids: highest_ranked_games
            })}>{humanizeNumber(in_charts_now)}</span>
          </div>
        </ShowIf>
      </div>
    </div>
  )
}

const Metric = ({clusters, metric, selectedCluster, setSelectedCluster, onSeeAll, resizeIndex, location}) => {

  const {call} = useContext(APIContext);
  const {showGame} = useContext(DetailsPanelContext);

  const plotRef = useRef();

  const plotWidth = useMemo(() => {
    return plotRef?.current?.el?.offsetWidth;
  }, [resizeIndex, plotRef.current]);
  const {name, plotly_plot, value_max, value_min, group_metrics, game_ids_title} = metric;

  const selectedMetricData = useMemo(() => {
    return metric.group_metrics.find(metric => metric.topic_id === selectedCluster);
  }, [selectedCluster, metric.group_metrics]);

  const selectedClusterData = useMemo(() => {
    return clusters.find(cluster => cluster.topic_query.id === selectedCluster);
  }, [clusters, selectedCluster]);

  const color = selectedClusterData?.color;

  function onClickedCluster(metric) {
    setSelectedCluster(metric.topic_id);
  }

  const groupMetrics = useMemo(() => {
    let result = [];
    clusters.forEach(cluster => {
      let matchingMetric = group_metrics.find(metric => metric.topic_id === cluster.topic_query?.id);
      if (matchingMetric) {
        result.push({metricData: matchingMetric, cluster});
      }
    });
    return result;
  }, [group_metrics, clusters]);

  async function onPlotClick({points = []}) {
    let ids = points[0]?.customdata;
    if (ids?.length === 1) {
      let id = ids[0];
      if(id && typeof id === "string") {
        let response = await call(getGameInformation, {id});
        if (response.ok) {
          showGame(response.body, undefined, location);
        }
      }
    }
  }

  const {plotData, plotLayout} = useMemo(() => {
    let {layout, data} = plotly_plot || {};

    let plotData = JSON.parse(JSON.stringify(data));
    const clusterTitles = clusters.map(({title}) => title);
    const plotTitles = plotData.map(({name}) => name)

    plotData = plotData.map(plotData => {
      if (clusterTitles.includes(plotData.name) && plotTitles.includes(selectedClusterData?.title)) {
        let selected = plotData.name === selectedClusterData.title;
        let opacity = selected ? 1 : 0.7;
        if (plotData.line) {
          let color = PerformanceUtils.hexToRgbA(plotData.line.color).join(',');
          plotData.line.color = `rgba(${color},${opacity})`;
        }
        plotData.opacity = opacity;
        if (plotData.marker) plotData.marker.opacity = opacity;
        if (plotData.marker.line) plotData.marker.line.opacity = opacity;
      }
      return plotData;
    });

    return {plotData, plotLayout: layout};
  }, [plotly_plot, selectedClusterData]);

  return (
    <div className="metric">
      <Grid container className="content">
        <Grid item className="content-left" sm="auto" lg="auto">
          <span className="name">
            {name} <Hint hint={metric.help} iconClassName="help"/>
          </span>
          <Grid container className="data-wrapper">
            <div className="comparison">
              <div className="bar-graph">
                {groupMetrics.map(({metricData, cluster}, index) => {
                  const {value, value_text} = metricData;
                  const maxSize = 100;
                  const color = cluster?.color;

                  const isRight = value >= 0;
                  const totalDiff = Math.abs(value_max - value_min);
                  const percentToPixelRatio = maxSize / totalDiff;
                  const leftSidePx = value_min < 0 ? percentToPixelRatio * Math.min(Math.abs(value_min), Math.abs(totalDiff)) : 0;
                  const rightSidePx = maxSize - leftSidePx;

                  const leftInnerWidth = isRight ? 0 : Math.abs(value / value_min) * leftSidePx;
                  const rightInnerWidth = isRight && value_max !== 0 ? value / value_max * rightSidePx : 0;

                  const spanStyle = {color}
                  let className = "bar-wrapper pointer";
                  if (selectedCluster === metricData.topic_id) className += " active";

                  const leftBarInnerStyle = {
                    width: leftInnerWidth + "px",
                    borderLeft: `1px solid ${color}`,
                    background: color,
                  };
                  const rightBarInnerStyle = {
                    ...leftBarInnerStyle,
                    width: rightInnerWidth + "px"
                  };

                  return (
                    <div className={className}
                         key={index}
                         onClick={() => onClickedCluster(metricData)}
                    >
                      <div className="bar left" style={{width: leftSidePx + "px"}}>
                        <div className="bar-inner" style={leftBarInnerStyle}/>
                      </div>
                      <div className="bar right">
                        <div className="bar-inner" style={rightBarInnerStyle}/>
                        <span style={spanStyle}>{value_text}</span>
                      </div>
                      <div className="filler"/>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className={"cluster description-box rounded-box " + (!!selectedMetricData ? "active" : "inactive")}
                 style={getClusterStyle(selectedClusterData)}>
              <Chip
                label={selectedClusterData?.title}
                style={{background: color}}
              />
              <span className="title" style={{color}}>
                {selectedMetricData?.value_text}
              </span>
              <span className="description">
                {selectedMetricData?.text}
              </span>
              <ShowIf condition={(selectedMetricData?.game_ids || []).length > 0}>
                <>
                  <span className="keywords">
                    {game_ids_title}
                  </span>
                  <GameIcons
                    ids={selectedMetricData?.game_ids}
                    key={selectedMetricData?.game_ids}
                    onClickMore={() => onSeeAll({
                      metric: selectedMetricData,
                      cluster: selectedClusterData
                    })}
                  />
                </>
              </ShowIf>
            </div>
          </Grid>
        </Grid>
        <Grid item className="plot" sm={0} lg="auto" style={{minWidth: plotWidth < 450 ? "100%" : "449px"}}>
          <ErrorBoundary fallback="An error has occurred">
            <Plot
              ref={plotRef}
              onClick={onPlotClick}
              data={plotData}
              layout={plotLayout}
              useResizeHandler={true}
              style={{width: "100%"}}
            />
          </ErrorBoundary>
        </Grid>
      </Grid>
    </div>
  );
}

export const GameIcons = ({ids = DEFAULT_ARRAY, onClickMore, size, showNumber = SHOWN_GAMES}) => {

  const {call} = useContext(APIContext);
  const [games, setGames] = useState();
  const {showGame} = useContext(DetailsPanelContext);

  const slicedIds = useMemo(() => {
    return ids.slice(0, showNumber);
  }, [ids, showNumber]);

  let additional = humanizeNumber((size || ids.length) - slicedIds.length);

  useEffect(() => {
    call(getGamesInformation, {data: {ids: slicedIds}}).then(response => {
      if (response.ok) {
        setGames(response.body);
      }
    })
  }, [slicedIds]);

  return (
    <ShowIf condition={ids.length > 0}>
      <div className="game-icons">
        <ShowIf condition={ids.length > 0 && !!games}>
          {(games || []).map(game =>
            <>
              <Tooltip
                title={game.title}
                arrow
                PopperProps={{className: "MuiTooltip-popper MuiTooltip-popperArrow secondary"}}
                placement="top"
              >
              <span>
                <GameIcon game={game} key={game._id} onClick={showGame} fallbackToCover={true}/>
              </span>
              </Tooltip>
            </>
          )}
          {!!additional && <span className="see-all pl-2" onClick={onClickMore}>+{additional}</span>}
        </ShowIf>
        {!games && <CircularProgress size={15}/>}
      </div>
    </ShowIf>
  );

}

const AnalysisForm = ({onSubmit, loading, sourceCountry = {}}) => {

  const [persistedData, setPersistedData] = useState();

  const CLUSTER_WIDTHS = [
    {
      label: <span>👍️ Balanced reach</span>,
      description: "Analyze a sensible group of games around the query",
      value: 2.5
    },
    {
      label: <span>🎯️ Focused</span>,
      description: "Analyze a group of games tightly related to the query",
      value: 1.0
    },
    {
      label: <span>🌐 Wide</span>,
      description: "Analyze a broader group of games around the query",
      value: 4.0
    }
  ];

  const initialValues = {
    search: [],
    cluster_width: CLUSTER_WIDTHS[0].value,
  };

  function overrideChangeData(data, formik) {
    if (data.topic) {
      let values = {
        ...formik.values,
        search: [topicToUniversalOption(data.topic)],
        cluster_width: 1.0
      }
      let sourceCountryData = {...sourceCountry};
      if (data.sources) sourceCountryData.sources = data.sources;

      onSubmit(values, formik, undefined, sourceCountryData, false, true);
    }
  }

  return (
    <div className="form-wrapper pb-3">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {formik => (
          <Form>
            <FormikPersist name="CompetitiveAnalysis.Form" onLoad={data => setPersistedData(data)}/>
            {!!persistedData && <ChangeDataOnLocation shouldOverride={() => true} override={overrideChangeData}/>}
            <div className="d-flex flex-column">
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Grid item sm={12} md={12} xs={12} className="input-fields-wrapper">
                  <div className="d-flex input-fields">
                    <FormikSelectField
                      className="mode-field"
                      name="cluster_width"
                      label="Topic Reach"
                      options={CLUSTER_WIDTHS.map(({value, label, description}) => {
                        return {
                          value,
                          label: (
                            <div className="d-flex flex-column">
                                <span className="font-weight-bold">
                                  {label}
                                </span>
                              <span className="description ml-4 pl-1" style={{opacity: 0.8}}>
                                  {description}
                                </span>
                            </div>)
                        }
                      })}
                      fullWidth
                    />
                    <UniversalInput
                      label="Type the game topic, or choose a game idea or an existing game"
                      name="search"
                      formik={formik}
                      onSetData={(data) => {
                        formik.setFieldValue("search", data);
                      }}
                      value={formik.values.search}
                      uploadMedia={true}
                      allowed={['text', 'game', 'generated_game', 'gdd', 'topic']}
                    />
                  </div>
                </Grid>
                <div className="d-flex align-self-center">
                  <GeneratingButton
                    id="competitive-analysis.analyse"
                    label="Add to Analysis"
                    className="gradient"
                    loadingText="Analysing..."
                    loading={loading}
                    style={{margin: 0, marginLeft: "10px"}}
                    disabled={formik.values.search.length === 0}
                    trackOptions={{
                      ...formik.values,
                      search: convertUniversalInput(formik.values.search)
                    }}
                    loadProgressSecs={5}
                  />
                </div>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function humanizeNumber(number) {
  if (number >= 1000) {
    number = number / 1000.0;
    number = number.toFixed(0) + "k";
  }
  return number;
}

function getClusterStyle(cluster, isActive = true) {
  const rgbaColor = PerformanceUtils.hexToRgbA(cluster?.color) || [];
  return {
    border: isActive ? `3px solid ${rgbaColorString(rgbaColor, 0.15)}` : '3px solid transparent',
    background: isActive ? rgbaColorString(rgbaColor, 0.03) : undefined
  }
}

function rgbaColorString(colorArray, opacity) {
  return `rgba(${colorArray.join(',')},${opacity})`
}

const ValidationSchema = Yup.object().shape({});

export default CompetitiveAnalysis;
