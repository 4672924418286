import React, {useContext, useMemo} from "react";
import ShowIf from "components/common/ShowIf";
import {MenuTopBar} from "pages/GDD3/GDDSideMenu";
import {
  AutoAwesomeOutlined,
  BurstModeOutlined,
  FavoriteOutlined,
  ModeEditOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import GDDContext from "context/GDDContext";
import {
  MoodboardFavorites,
  MoodboardGenerator,
  MoodboardSearch,
  MoodboardSuggestions
} from "pages/GDD3/GDDSideMenu/MoodboardMenu";
import {IMAGE_MENUS} from "pages/GDD3/GDDSideMenu/GameElementsMenu";

export const MENUS = {
  suggestionsGenerated: {
    id: 'suggestionsGenerated',
    label: 'Ludo\'s Suggestions',
    buttonLabel: <AutoAwesomeOutlined className="font-size-lg mr-2"/>
  },
  editImage: {
    id: 'editImage',
    label: 'Edit Image',
    buttonLabel: <ModeEditOutlined/>
  },
  generator: {
    id: 'generator',
    label: 'Generate Image',
    buttonLabel: <BurstModeOutlined/>
  },
  search: {
    id: 'search',
    label: 'Search for Images',
    buttonLabel: <SearchOutlined/>
  },
  favorites: {
    id: 'favorites',
    label: 'From Favorites',
    buttonLabel: <FavoriteOutlined/>
  },
}

const HeaderMenu = ({component, gdd}) => {

  const {menu} = useContext(GDDContext);

  const section = useMemo(() => {
    return gdd.sections.find(({name}) => name === component.section);
  }, [gdd, component.section]);

  const imageType = menu?.subSection === "icon" ? menu?.subSection : "art";

  return (
    <div className="menu-section moodboard">
      <MenuTopBar/>
      <div className="menu-section-content" key={menu?.subSection}>
        <ShowIf condition={menu?.option === MENUS.suggestionsGenerated.id}>
          <MoodboardSuggestions section={menu?.section} value={section?.value} component={component} generated={true} imageType={imageType}  autoSuggest={false} artStyle={gdd.art_style}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.favorites.id}>
          <MoodboardFavorites section={menu?.section} value={section?.value} name={menu?.subSection}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.search.id}>
          <MoodboardSearch section={menu?.section} value={section?.value} gdd={gdd}/>
        </ShowIf>
        <ShowIf condition={menu?.option === MENUS.generator.id}>
          <MoodboardGenerator key={"generate-"+menu?.subSection} section={menu?.section} value={section?.value} artStyle={gdd.art_style} gdd={gdd} initialImage={undefined} imageType={imageType}/>
        </ShowIf>
        <ShowIf condition={menu?.option === IMAGE_MENUS.editImage.id}>
          <MoodboardGenerator key={"edit-"+menu?.subSection} section={menu?.section} value={section?.value} artStyle={gdd.art_style} gdd={gdd} initialImage={menu.image}/>
        </ShowIf>
      </div>
    </div>
  );
}

export default HeaderMenu;
