import React, { useState, useContext, useEffect } from "react";
import JSZip from "jszip";
import "./export-project.scss";
import {
  AddCircleOutline,
  CardMembershipOutlined,
  CheckCircleOutline,
  ChecklistRtlOutlined,
  CodeOutlined,
  DownloadOutlined,
  FileCopyOutlined,
  ReplayOutlined,
  SyncOutlined,
} from "@mui/icons-material";
import APIContext from "../../context/APIContext";
import { CircularProgress, TextField, Tooltip } from "@material-ui/core";
import { IconPlaceholder } from "./GDD4Menu";
import { useSnackbar } from "notistack";
import { Hint } from "../../scenes/Headquarters";
import PerformanceUtils from "../../helpers/PerformanceUtils";
import SocketContext from "../../context/SocketContext";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import MyButton, {
  ConfirmDialog,
  GeneratingButton,
} from "../../components/Controls/MyButton";
import { GDDModal } from "../GDD3/Helpers";
import AuthContext from "../../context/AuthContext";
import { isFreePlan } from "../../scenes/SubscriptionPage/Plans";
import { BillingModal } from "../Profile/Profile";

const getProjectCode = "getProjectCode";
const generateProjectCode = "generateProjectCode";
const generateScriptPrompt = "generateScriptPrompt";

const ExportProject = ({ projectId, gdd }) => {
  const { call, loading } = useContext(APIContext);
  const { instance } = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useContext(SocketContext);

  const { auth } = useContext(AuthContext);
  const allowEverything = !isFreePlan(auth.subscription.plan, auth.subscription);

  const [openBilling, setOpenBilling] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [items, setItems] = useState(undefined);
  const [loadingSingleScript, setLoadingSingleScript] = useState({});
  const [regeneratePrompt, setRegeneratePrompt] = useState(false);
  const [generatePrompt, setGeneratePrompt] = useState(false);
  const [confirmGenerateAll, setConfirmGenerateAll] = useState(false);

  const isLoadingAllCode = loading[generateProjectCode];

  console.log(items);

  useEffect(() => {
    call(getProjectCode, { projectId }).then((response) => {
      if (response.ok) {
        setItems(response.body);
        setSelectedItem(response.body.find((item) => !!item.script));
      }
    });
  }, [projectId]);

  async function generateAll() {
    track("gdd.code.generate-all", { projectId });
    setSelectedItem();
    setItems([]);
    let streamingKey = PerformanceUtils.generateId();

    if (instance) {
      instance.on(streamingKey, ({ type, data }) => {
        if (type === "scripts") setItems(data);
        if (type === "script")
          setItems((prevItems) =>
            PerformanceUtils.editOrAdd(data, prevItems, "_id")
          );
      });
    }

    let response = await call(generateProjectCode, { projectId, streamingKey });
    if (response.ok) {
      setItems(response.body);
      setSelectedItem(response.body.find((item) => !!item.script));
    }
    if (instance) instance.removeListener(streamingKey);
  }

  async function generateSingle(scriptId, prompt) {
    track("gdd.code.generate-script", { projectId, scriptId });
    setLoadingSingleScript((prevState) => ({
      ...prevState,
      [scriptId || "new"]: true,
    }));

    let response = await call(generateScriptPrompt, {
      projectId,
      scriptId,
      prompt: { value: prompt },
    });
    if (response.ok) {
      let data = response.body;
      setItems((prevItems) =>
        PerformanceUtils.editOrAdd(data, prevItems, "_id")
      );
      if (selectedItem.filename === data.filename) {
        setSelectedItem(data);
      }
    }
    setLoadingSingleScript((prevState) => ({
      ...prevState,
      [scriptId || "new"]: false,
    }));
  }

  async function downloadAll() {
    track("gdd.code.download-all", { projectId });
    const projectName = gdd.sections[0]?.value?.title;

    const zip = new JSZip();
    items.forEach((item) => {
      zip.file(`${item.filename}`, item.script);
    });

    const content = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = `${projectName} scripts.zip`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async function onCopyCode() {
    track("gdd.code.copy-code", { projectId });
    if (selectedItem && selectedItem.script) {
      try {
        await navigator.clipboard.writeText(selectedItem.script);
        enqueueSnackbar("Code copied to clipboard", { variant: "success" });
      } catch (err) {
        enqueueSnackbar("Failed to copy code", { variant: "error" });
      }
    }
  }

  async function onDownloadFile() {
    track("gdd.code.download-script", { projectId });
    const content = selectedItem.script;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(content);
    link.download = selectedItem.filename;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  function onOpenBilling() {
    setOpenBilling(true);
  }

  let rightColumnClassName = "right-column";
  if (items?.length === 0 || !selectedItem?.script)
    rightColumnClassName += " no-code";

  return (
    <div className="export-project">
      <BillingModal open={openBilling} onClose={() => setOpenBilling(false)} />
      {items && (
        <>
          <div className="left-column">
            {items.length > 0 && (
              <div className="action-bar">
                <SubscribeTooltip
                  show={!allowEverything}
                  openBilling={() => setOpenBilling(true)}
                >
                  <span
                    onClick={
                      loadingSingleScript.new
                        ? undefined
                        : () => setGeneratePrompt(true)
                    }
                  >
                    {loadingSingleScript.new ? (
                      <CircularProgress size={15} />
                    ) : (
                      <AddCircleOutline />
                    )}{" "}
                    New Script
                  </span>
                </SubscribeTooltip>
                <SubscribeTooltip
                  show={!allowEverything}
                  openBilling={() => setOpenBilling(true)}
                >
                  <span onClick={() => setConfirmGenerateAll(true)}>
                    <ChecklistRtlOutlined /> Re-generate All
                  </span>
                </SubscribeTooltip>
                {items.length > 0 && (
                  <span onClick={downloadAll}>
                    <DownloadOutlined /> Download All
                  </span>
                )}
              </div>
            )}
            <div className="items">
              {items.length === 0 && (
                <div className="no-code">
                  <span className="main-title gradient-text3">Unity Code</span>
                  <span className="sub-title gradient-text3">
                    Press this button to generate your Unity scripts and
                    kickstart the implementation of this game.
                  </span>
                  <GeneratingButton
                    id="gdd.code.generate-list"
                    className="gradient"
                    label="Generate Scripts"
                    loading={isLoadingAllCode}
                    loadProgressSecs={10}
                    onClick={generateAll}
                  />
                </div>
              )}
              {items.map((item) => (
                <div
                  className={
                    "item" +
                    (selectedItem?.name === item.name ? " active" : "") +
                    (!item.script ? " disabled" : "")
                  }
                  onClick={() => setSelectedItem(item)}
                >
                  <CodeOutlined />{" "}
                  <span className="description">{item.name}</span>
                  <Hint
                    hint={
                      item.explanation + "\n\n" + item.implementation_details
                    }
                    iconClassName="help"
                  />
                  {loadingSingleScript[item._id] ? (
                    <CircularProgress className="text-white" size={20} />
                  ) : !item.script && isLoadingAllCode ? (
                    <CircularProgress className="text-white" size={20} />
                  ) : item.script ? (
                    <CheckCircleOutline
                      style={{ top: "2px", position: "relative" }}
                    />
                  ) : (
                    allowEverything && (
                      <SyncOutlined
                        className="pointer"
                        onClick={() => generateSingle(item._id)}
                      />
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={rightColumnClassName}>
            {selectedItem && (
              <>
                <div className="action-bar">
                  {loadingSingleScript[selectedItem._id] || isLoadingAllCode ? (
                    <CircularProgress size={24} />
                  ) : selectedItem.script ? (
                    <>
                      <SubscribeTooltip
                        show={!allowEverything}
                        openBilling={() => setOpenBilling(true)}
                      >
                        <span onClick={() => setRegeneratePrompt(true)}>
                          <ReplayOutlined /> Re-generate Script
                        </span>
                      </SubscribeTooltip>
                      <span onClick={onCopyCode}>
                        <FileCopyOutlined /> Copy Code
                      </span>
                      <span onClick={onDownloadFile}>
                        <DownloadOutlined /> Download Script
                      </span>
                    </>
                  ) : (
                    !allowEverything && (
                      <>
                        <span onClick={onOpenBilling}>
                          <CardMembershipOutlined /> You need a subscription in
                          order to generate all scripts
                        </span>
                      </>
                    )
                  )}
                </div>
                {selectedItem.script && (
                  <div className="script">
                    <SyntaxHighlighter
                      showLineNumbers={true}
                      language="csharp"
                      style={docco}
                      lineNumberStyle={{
                        opacity: 0.35,
                      }}
                    >
                      {selectedItem.script}
                    </SyntaxHighlighter>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {regeneratePrompt && (
        <RegeneratePromptModal
          onClose={() => setRegeneratePrompt(false)}
          onRegenerate={(text) => generateSingle(selectedItem._id, text)}
          title="Re-generate Script"
          description="What changes do you want to make to this script? This will overwrite the existing code."
          buttonLabel="Re-generate"
        />
      )}
      {generatePrompt && (
        <RegeneratePromptModal
          onClose={() => setGeneratePrompt(false)}
          onRegenerate={(text) => generateSingle(undefined, text)}
          title="Generate New Script"
          description="What should this script do?"
          buttonLabel="Generate"
        />
      )}
      {confirmGenerateAll && (
        <ConfirmDialog
          title="Generate All Scripts"
          reverse={true}
          textElement={
            <span>
              This will replace your existing scripts. Are you sure you want to
              proceed?
            </span>
          }
          onCancel={() => setConfirmGenerateAll(false)}
          onConfirm={() => {
            generateAll();
            setConfirmGenerateAll(false);
          }}
          open={confirmGenerateAll}
          confirmLabel="Generate All"
        />
      )}
    </div>
  );
};

const SubscribeTooltip = ({ show, children, openBilling }) => {
  return show ? (
    <Tooltip
      arrow
      placement="top"
      title="This feature is available to subscribers only"
      PopperProps={{
        disablePortal: true,
        className: "MuiTooltip-popper MuiTooltip-popperArrow secondary",
      }}
    >
      <div className="disabled" onClick={openBilling}>
        {children}
      </div>
    </Tooltip>
  ) : (
    children
  );
};

const RegeneratePromptModal = ({
  onClose,
  onRegenerate,
  title,
  description,
  buttonLabel,
}) => {
  const [changes, setChanges] = useState("");

  const handleRegenerate = () => {
    onRegenerate(changes);
    onClose();
  };

  return (
    <GDDModal
      open={true}
      onClose={onClose}
      className="regenerate-prompt-modal"
      title={title}
    >
      <div className="content">
        <div className="description">{description}</div>
        <TextField
          fullWidth
          multiline
          rows={2}
          variant="outlined"
          value={changes}
          onChange={(e) => setChanges(e.target.value)}
          margin="normal"
        />
        <div className="flex-row d-flex" style={{ justifyContent: "center" }}>
          <MyButton
            className="gradient"
            onClick={handleRegenerate}
            disabled={changes.trim() === ""}
          >
            {buttonLabel}
          </MyButton>
        </div>
      </div>
    </GDDModal>
  );
};

export const ExportProjectHeader = ({ gdd }) => {
  const projectName = gdd.sections[0]?.value?.title;

  return (
    <div className="export-project-header">
      <div className="icon">
        <IconPlaceholder icon={gdd.icon} />
      </div>
      <div className="description gradient-text2">
        Generate and Export <b>Unity Code</b> to accelerate{" "}
        <i>
          <b>{projectName}</b>
        </i>
        's development!
      </div>
    </div>
  );
};

export default ExportProject;
